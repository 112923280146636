import { type GetServerSidePropsContext, type GetServerSidePropsResult } from 'next';
import { QueryClient } from 'react-query';

import { apiClient, APIClient } from '@api/index';
import { setCommonPageData } from './setCommonPageData';
import { ISSRPageProps } from './types';
/**
 * Получить общие для всех страниц данные
 */
export const getCommonProps = async <TProps extends ISSRPageProps>(
    context: GetServerSidePropsContext,
    fn: (
        params: any,
        queryClient: QueryClient,
        apiClient: APIClient
    ) => Promise<GetServerSidePropsResult<TProps>>
) => {
    const resultingProps: Record<string, any> = {};

    try {
        const { req } = context;
        const isSSR = !req.url?.includes('/_next/data/')!;
        resultingProps.isSSR = isSSR;

        const queryClient = new QueryClient();
        if(isSSR){
            setCommonPageData(queryClient);
        }
    
        const result = await fn(
            {
                isSSR,
                hasSSRError: false,
            },
            queryClient,
            apiClient
        );
        return result
    
    } catch (err) {
        console.error('SSR error:', err, 'resultingProps=',resultingProps);

        return Promise.resolve({
            props: {
                ...resultingProps,
                statusCode: 500,
                err: JSON.stringify(err),
                hasSSRError: true,
            },
            notFound: true,
        } as never as GetServerSidePropsResult<TProps>);
    }
};