import { CSSObject } from '@emotion/core';

import { colors, scale, typography } from '@scripts/gds';

export const featureStyles = (sm: string): CSSObject => ({
    backgroundColor: colors.white,
    padding: `${scale(3) + 1}px ${scale(2) + 1}px`,
    display: 'flex',
    lineHeight: '19.2px !important',
    borderRadius: '6px',
    gap: '18px',
    width: '100%',
    maxWidth: '306px',
    minWidth: '306px',
    height: '123px',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    transition: 'color .3s',
    ':hover': {
        color: colors.white,
        '& div': {
            color: colors.white,
        },
    },
    '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '350%',
        height: '250%',
        background: colors.primary,
        zIndex: -1,
        transformOrigin: '0% 0%',
        transform: 'translateX(-50%) translateY(0) rotate(20deg)',
        transition: 'transform .3s ease-in',
    },
    '&:hover::after': {
        transform: 'translateY(-115%) translateX(-450px) rotate(20deg)',
    },
    [sm]: {
        gap: '8px',
        minWidth: '292px',
        padding: `${scale(3) + 1}px ${scale(2) - 6}px`,
    },
});

export const linkStyles: CSSObject = {
    backgroundColor: colors.white,
    position: 'absolute',
    padding: `${scale(1, false, 14)}px ${scale(2)}px`,
    ...typography('bodyMdMedium'),
    borderRadius: scale(1),
    bottom: scale(4),
    left: scale(1, false, 29),
    zIndex: 2,
    color: colors.primary,
    border: '1px solid transparent',
    ':hover': {
        backgroundColor: colors?.grey200,
        color: colors.secondary,
    },
};
