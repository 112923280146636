import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';

import { useCategories } from '@api/catalog';
import { getPagination } from '@api/common/helpers';

import Carousel from '@components/controls/Carousel';

import { colors, scale, typography } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

const PopularCategoriesCarousel = () => {
    const { lg } = useMedia();

    const { data } = useCategories({
        pagination: getPagination(12),
        filter: { is_active: true, is_real_active: true, is_promo: true },
    });
    const popularCategories = useMemo(
        () => data?.data?.map(item => ({ ...item, link: `/catalog/${item.code}` })),
        [data]
    );

    return popularCategories ? (
        <Carousel spaceBetween={scale(3)} type="categories" navigation title="Популярные категории" lazy>
            {popularCategories.map((item, index) => (
                <Carousel.Item key={index}>
                    <div
                        css={{
                            padding: scale(3),
                            backgroundColor: colors.backgroundMain,
                            ...typography('bodyMdRegular'),
                            height: scale(23),
                            width: '100%',
                            textAlign: 'center',
                            [lg]: {
                                width: scale(24),
                            },
                        }}
                    >
                        <Link legacyBehavior href={item.link} passHref prefetch={false}>
                            <a
                                aria-label={item.name}
                                css={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: scale(3, true),
                                    height: '100%',
                                }}
                            >
                                <div css={{ position: 'relative', width: '100%', height: '100%' }}>
                                    {item.image && (
                                        <Image
                                            src={item.image}
                                            placeholder="blur"
                                            blurDataURL={item.image}
                                            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                            fill
                                            alt={item.name}
                                            css={{ objectFit: 'contain' }}
                                        />
                                    )}
                                </div>
                                {item.name}
                            </a>
                        </Link>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    ) : null;
};

export default PopularCategoriesCarousel;
