import { QueryClient } from 'react-query';

import { getCategoriesTree } from '@api/catalog/categories/api';
import { getPagination } from '@api/common/helpers';
import { getMenu } from '@api/content/api';

const commonParams = {
    pagination: getPagination(-1),
    include: ['items'],
};

export const setCommonPageData = async (queryClient: QueryClient) => {
    const { key: categoriesKey, fetch: categoriesKeyFetch } = getCategoriesTree();
    const { key: headerOptionsKey, fetch: headerOptionsFetch } = getMenu({
        filter: { code: 'header_help' },
        ...commonParams,
    });
    const { key: headerMainKey, fetch: headerMainFetch } = getMenu({
        filter: { code: 'header_main' },
        ...commonParams,
    });
    const { key: footerMainKey, fetch: footerMainFetch } = getMenu({
        filter: { code: 'footer_main' },
        ...commonParams,
    });

    await Promise.all([
        queryClient.prefetchQuery(headerOptionsKey, headerOptionsFetch),
        queryClient.prefetchQuery(headerMainKey, headerMainFetch),
        queryClient.prefetchQuery(footerMainKey, footerMainFetch),
        queryClient.prefetchQuery(categoriesKey, categoriesKeyFetch),
    ]);
};
