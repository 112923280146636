import { CSSObject } from '@emotion/core';
import Link from 'next/link';

import Carousel from '@components/controls/Carousel';
import Picture from '@components/controls/future/Picture';

import { Layout, colors, scale, typography } from '@scripts/gds';
import { transformImageUrl } from '@scripts/helpers';
import { useMedia } from '@scripts/hooks';

import { MainBannersProps } from './types';

const buttonStyles: CSSObject = {
    position: 'absolute',
    backgroundColor: colors.white,
    borderRadius: scale(1),
    color: colors.primary,
    bottom: scale(3),
    ...typography('bodyMdMedium'),
    ':hover': {
        backgroundColor: colors.grey200,
        color: colors.secondary,
    },
};

const MainBanners = ({ slider, right }: MainBannersProps) => {
    const { md, sm, xs } = useMedia();

    const mainBanners = slider?.data || [];
    const rightBanner = right?.data || [];

    return (
        <>
            <Layout
                cols={4}
                css={{
                    height: '344px',
                    [sm]: {
                        height: '344px',
                    },
                    [xs]: {
                        height: '264px',
                    },
                }}
            >
                <Layout.Item col={{ xxxl: 3, sm: 4 }} css={{ width: '100%' }}>
                    {mainBanners.length > 0 && (
                        <Carousel
                            type="main-banners"
                            pagination
                            spaceBetween={scale(3)}
                            slidesPerView={1}
                            css={{ width: '100%' }}
                            lazy
                            autoplay
                        >
                            {mainBanners.map((item, index) => (
                                <Carousel.Item key={index}>
                                    <div
                                        css={{
                                            height: scale(40),
                                            width: '100%',
                                            position: 'relative',
                                            [sm]: {
                                                height: scale(38),
                                            },
                                            [xs]: {
                                                height: scale(30),
                                            },
                                        }}
                                    >
                                        {item.url && item.button?.text && (
                                            <Link legacyBehavior href={item.url} passHref prefetch={false}>
                                                <a
                                                    aria-label={item.button.text}
                                                    css={{
                                                        ...buttonStyles,
                                                        left: scale(4),
                                                        padding: `${scale(1, false, 14)}px ${scale(2)}px`,
                                                    }}
                                                >
                                                    {item.button.text}
                                                </a>
                                            </Link>
                                        )}
                                        <Link legacyBehavior href={item.url || ''} passHref prefetch={false}>
                                            <Picture
                                                sources={[
                                                    {
                                                        media: '(max-width: 567px)',
                                                        image: transformImageUrl(
                                                            item.mobile_image || '',
                                                            0,
                                                            scale(30),
                                                            'we'
                                                        ),
                                                    },
                                                    {
                                                        media: '(max-width: 767px)',
                                                        image: transformImageUrl(
                                                            item.tablet_image || '',
                                                            0,
                                                            scale(38),
                                                            'we'
                                                        ),
                                                    },
                                                    {
                                                        media: '(min-width: 768px)',
                                                        image: transformImageUrl(
                                                            item.desktop_image || '',
                                                            0,
                                                            scale(40),
                                                            'we'
                                                        ),
                                                    },
                                                ]}
                                                loading={index === 0 ? 'eager' : 'lazy'}
                                                alt={item.name}
                                                width="auto"
                                                height="auto"
                                                placeholder="blur"
                                                style={{
                                                    objectFit: 'cover',
                                                    objectPosition: 'left center',
                                                    height: '100%',
                                                    width: '100%',
                                                    borderRadius: scale(1),
                                                    cursor: item.url ? 'pointer' : 'default',
                                                }}
                                            />
                                        </Link>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                </Layout.Item>

                <Layout.Item col={1} css={{ [md]: { display: 'none' } }}>
                    {rightBanner?.length > 0 && (
                        <div
                            css={{
                                height: scale(40),
                                width: scale(38),
                                position: 'relative',
                            }}
                        >
                            <Carousel pagination spaceBetween={scale(3)} slidesPerView={1} css={{ width: '100%' }} lazy>
                                {rightBanner.map((item, index) => (
                                    <Carousel.Item key={item.desktop_image}>
                                        {item?.url && item?.button?.text && (
                                            <Link legacyBehavior href={item.url} passHref prefetch={false}>
                                                <a
                                                    aria-label={item.button.text}
                                                    css={{
                                                        ...buttonStyles,
                                                        left: scale(3),
                                                        padding: `${scale(1, false, 14)}px ${scale(3)}px !important`,
                                                    }}
                                                >
                                                    {item.button.text}
                                                </a>
                                            </Link>
                                        )}
                                        <Link legacyBehavior href={item.url || ''} passHref prefetch={false}>
                                            <Picture
                                                sources={[
                                                    {
                                                        media: '(max-width: 567px)',
                                                        image: transformImageUrl(
                                                            item.mobile_image || '',
                                                            scale(38),
                                                            scale(40)
                                                        ),
                                                    },
                                                    {
                                                        media: '(max-width: 767px)',
                                                        image: transformImageUrl(
                                                            item.tablet_image || '',
                                                            scale(38),
                                                            scale(40)
                                                        ),
                                                    },
                                                    {
                                                        media: '(min-width: 768px)',
                                                        image: transformImageUrl(
                                                            item.desktop_image || '',
                                                            scale(38),
                                                            scale(40)
                                                        ),
                                                    },
                                                ]}
                                                loading={index === 0 ? 'eager' : 'lazy'}
                                                alt={item.name}
                                                width="auto"
                                                height="auto"
                                                placeholder="blur"
                                                style={{
                                                    cursor: item.url ? 'pointer' : 'default',
                                                    objectFit: 'fill',
                                                    height: scale(40),
                                                    width: '100%',
                                                    borderRadius: scale(1),
                                                }}
                                            />
                                        </Link>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    )}
                </Layout.Item>
            </Layout>
        </>
    );
};

export default MainBanners;
